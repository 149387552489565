import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServerStatusHttpService {

  private readonly http = inject(HttpClient);

  getStatus(): Observable<HttpResponse<void>> {
    return this.http.get<void>(`${environment.apiBaseUrl}/status`, { observe: 'response' });
  }

}
