/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ServerStatusHttpService } from '@core/services/server-status.http.service';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { Status } from '@shared/models/enums/status.enum';
import { TuiAlertService, TuiButton } from '@taiga-ui/core';
import { TuiButtonLoading } from '@taiga-ui/kit';
import { BehaviorSubject, filter, first } from 'rxjs';

@Component({
  templateUrl: './server-unavailable.component.html',
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    TuiButton,
    TuiButtonLoading,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerUnavailableComponent implements OnInit {

  Status = Status;
  isStatusChecking$ = new BehaviorSubject(false);

  private readonly router = inject(Router);
  private readonly serverStatusService = inject(ServerStatusHttpService);
  private readonly alertService = inject(TuiAlertService);

  ngOnInit(): void {
    this.retryConnection();
  }

  retryConnection(): void {
    this.isStatusChecking$.pipe(
      first(),
      filter((isStatusChecking) => !isStatusChecking),
    ).subscribe(() => {
      this.isStatusChecking$.next(true);
      this.serverStatusService.getStatus().subscribe({
        next: async (pingResult: any) => {
          if (pingResult.status === HttpStatusCode.Ok) {
            this.router.navigate([''], { queryParamsHandling: 'merge' });
          }
        },
        error: () => {
          const message = `Impossible d'accéder au service...`;
          const options = { label: 'Service indisponible', appearance: 'error' };
          this.alertService.open(message, options).subscribe();
          this.isStatusChecking$.next(false);
        },
      });
    });
  }

}
