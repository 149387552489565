import { DOCUMENT, AsyncPipe, NgIf } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, ChangeDetectorRef, Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { TUI_VERSION } from '@taiga-ui/cdk/constants';
import * as i1 from '@taiga-ui/cdk/directives/platform';
import { TuiPlatform } from '@taiga-ui/cdk/directives/platform';
import { tuiWatch, tuiZonefreeScheduler } from '@taiga-ui/cdk/observables';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk/tokens';
import { TuiAlerts } from '@taiga-ui/core/components/alert';
import { TUI_DIALOGS, TuiDialogs } from '@taiga-ui/core/components/dialog';
import { TuiScrollControls } from '@taiga-ui/core/components/scrollbar';
import { TuiDropdowns } from '@taiga-ui/core/directives';
import { TuiHints } from '@taiga-ui/core/directives/hint';
import { TuiBreakpointService } from '@taiga-ui/core/services';
import { TUI_REDUCED_MOTION, TUI_ANIMATIONS_SPEED, TUI_THEME } from '@taiga-ui/core/tokens';
import { tuiGetDuration } from '@taiga-ui/core/utils';
import { PreventEventPlugin } from '@taiga-ui/event-plugins';
import { map, of, debounceTime } from 'rxjs';

/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
const _c0 = ["*", [["tuiOverContent"]], [["tuiOverDialogs"]], [["tuiOverAlerts"]], [["tuiOverDropdowns"]], [["tuiOverHints"]]];
const _c1 = ["*", "tuiOverContent", "tuiOverDialogs", "tuiOverAlerts", "tuiOverDropdowns", "tuiOverHints"];
function TuiRoot_tui_scroll_controls_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-scroll-controls", 2);
  }
}
class TuiRoot {
  constructor() {
    this.reducedMotion = inject(TUI_REDUCED_MOTION);
    this.duration = tuiGetDuration(inject(TUI_ANIMATIONS_SPEED));
    this.isMobileRes = toSignal(inject(TuiBreakpointService).pipe(map(breakpoint => breakpoint === 'mobile'), tuiWatch(inject(ChangeDetectorRef))));
    this.scrollbars$ = inject(TUI_IS_MOBILE) ? of(false) : inject(TUI_DIALOGS).pipe(map(({
      length
    }) => !length), debounceTime(0, tuiZonefreeScheduler()));
    inject(DOCUMENT).defaultView?.document.documentElement.setAttribute('data-tui-theme', inject(TUI_THEME).toLowerCase());
    ngDevMode && console.assert(!!inject(EVENT_MANAGER_PLUGINS).find(plugin => plugin instanceof PreventEventPlugin), 'NG_EVENT_PLUGINS is missing from global providers');
  }
  static {
    this.ɵfac = function TuiRoot_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiRoot)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiRoot,
      selectors: [["tui-root"]],
      hostAttrs: ["data-tui-version", "4.7.0"],
      hostVars: 6,
      hostBindings: function TuiRoot_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("touchstart.passive.silent", function TuiRoot_touchstart_passive_silent_HostBindingHandler() {
            return 0;
          });
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("--tui-duration", ctx.duration, "ms")("--tui-scroll-behavior", ctx.reducedMotion ? "auto" : "smooth");
          i0.ɵɵclassProp("_mobile", ctx.isMobileRes());
        }
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.TuiPlatform]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 13,
      vars: 3,
      consts: [["class", "t-root-scrollbar", 4, "ngIf"], [1, "t-root-content"], [1, "t-root-scrollbar"]],
      template: function TuiRoot_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵtemplate(0, TuiRoot_tui_scroll_controls_0_Template, 1, 0, "tui-scroll-controls", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵelementStart(2, "tui-dropdowns")(3, "div", 1);
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(5, 1);
          i0.ɵɵelement(6, "tui-dialogs");
          i0.ɵɵprojection(7, 2);
          i0.ɵɵelement(8, "tui-alerts");
          i0.ɵɵprojection(9, 3);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(10, 4);
          i0.ɵɵelement(11, "tui-hints");
          i0.ɵɵprojection(12, 5);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.scrollbars$));
        }
      },
      dependencies: [AsyncPipe, NgIf, TuiAlerts, TuiDialogs, TuiDropdowns, TuiHints, TuiScrollControls],
      styles: ["@keyframes tuiPresent{to{content:\"\"}}@keyframes tuiSkeletonVibe{to{opacity:.5}}html[data-tui-theme],.tui-zero-scrollbar{scrollbar-width:none;-ms-overflow-style:none}html[data-tui-theme]::-webkit-scrollbar,.tui-zero-scrollbar::-webkit-scrollbar,html[data-tui-theme]::-webkit-scrollbar-thumb,.tui-zero-scrollbar::-webkit-scrollbar-thumb{display:none}body,input{margin:0}tui-root{position:relative;display:block;font:var(--tui-font-text-s);color:var(--tui-text-primary);flex:1;border-image:conic-gradient(var(--tui-background-base) 0 0) fill 0/0/0 0 100vh 0;-webkit-tap-highlight-color:transparent}tui-root>.t-root-scrollbar{position:fixed;top:0;left:0;bottom:0;right:0;display:none;margin:0}[data-tui-theme] tui-root>.t-root-scrollbar{display:block}.t-root-content{position:relative;top:var(--t-root-top);block-size:100%;isolation:isolate}.t-root-content>*{--t-root-top: 0}[tuiDropdownButton][tuiDropdownButton]{display:none}\n"],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRoot, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-root',
      imports: [AsyncPipe, NgIf, TuiAlerts, TuiDialogs, TuiDropdowns, TuiHints, TuiScrollControls],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.Default,
      hostDirectives: [TuiPlatform],
      host: {
        'data-tui-version': TUI_VERSION,
        '[style.--tui-duration.ms]': 'duration',
        '[style.--tui-scroll-behavior]': 'reducedMotion ? "auto" : "smooth"',
        '[class._mobile]': 'isMobileRes()',
        // Required for the :active state to work in Safari. https://stackoverflow.com/a/33681490
        '(touchstart.passive.silent)': '0'
      },
      template: "<tui-scroll-controls\n    *ngIf=\"scrollbars$ | async\"\n    class=\"t-root-scrollbar\"\n/>\n<tui-dropdowns>\n    <div class=\"t-root-content\">\n        <ng-content />\n    </div>\n    <ng-content select=\"tuiOverContent\" />\n    <tui-dialogs />\n    <ng-content select=\"tuiOverDialogs\" />\n    <tui-alerts />\n    <ng-content select=\"tuiOverAlerts\" />\n</tui-dropdowns>\n<ng-content select=\"tuiOverDropdowns\" />\n<tui-hints />\n<ng-content select=\"tuiOverHints\" />\n",
      styles: ["@keyframes tuiPresent{to{content:\"\"}}@keyframes tuiSkeletonVibe{to{opacity:.5}}html[data-tui-theme],.tui-zero-scrollbar{scrollbar-width:none;-ms-overflow-style:none}html[data-tui-theme]::-webkit-scrollbar,.tui-zero-scrollbar::-webkit-scrollbar,html[data-tui-theme]::-webkit-scrollbar-thumb,.tui-zero-scrollbar::-webkit-scrollbar-thumb{display:none}body,input{margin:0}tui-root{position:relative;display:block;font:var(--tui-font-text-s);color:var(--tui-text-primary);flex:1;border-image:conic-gradient(var(--tui-background-base) 0 0) fill 0/0/0 0 100vh 0;-webkit-tap-highlight-color:transparent}tui-root>.t-root-scrollbar{position:fixed;top:0;left:0;bottom:0;right:0;display:none;margin:0}[data-tui-theme] tui-root>.t-root-scrollbar{display:block}.t-root-content{position:relative;top:var(--t-root-top);block-size:100%;isolation:isolate}.t-root-content>*{--t-root-top: 0}[tuiDropdownButton][tuiDropdownButton]{display:none}\n"]
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiRoot };
