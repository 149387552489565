import { MenuActionId } from '@shared/models/enums/menu-action-id.enum';
import { MenuAction } from '@shared/models/menu-action';

export abstract class ActionsService {
  abstract actions: MenuAction[];

  getAction(id: MenuActionId): MenuAction | undefined {
    return this.actions.find((action) => action.id === id);
  }

  getActions(): MenuAction[] {
    return this.actions;
  }
}
