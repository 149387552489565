import { Component } from '@angular/core';
import { TitleComponent } from '@shared/components/title/title.component';
import { Size } from '@shared/models/enums/size.enum';
import { TuiIcon } from '@taiga-ui/core';

@Component({
  selector: 'pxc-deposit-token-error',
  standalone: true,
  imports: [TitleComponent, TuiIcon],
  templateUrl: './deposit-token-error.component.html',
})
export class DepositTokenErrorComponent {
  Size = Size;
  readonly title = `Le lien de votre télésuivi est invalide`;
  readonly subtitle = `Veuillez contacter vos soignants pour recevoir un nouveau lien.`;
}
