<main
  class="overflow-x-hidden min-h-screen transition animate-in fade-in bg-slate-50"
>
  <pxc-header
    class="sticky top-0 z-30 pointer-events-none bg-white"
    data-testid="navbar"
  ></pxc-header>

  <div
    class="h-full w-full relative container py-8 flex mx-auto [&>*:not(router-outlet)]:w-full max-w-prose"
  >
    <router-outlet data-testid="content"></router-outlet>
  </div>
</main>
