import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { tokenInterceptor } from '@core/interceptors/token.interceptor';
import { unauthorizedInterceptor } from '@core/interceptors/unauthorized.interceptor';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NG_EVENT_PLUGINS } from '@taiga-ui/event-plugins';
import { environment } from '../environments/environment';
import { routes } from './app.routes';

export const appInsightsFactory = (): ApplicationInsights =>
  new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
    },
  });

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(
      withInterceptors([tokenInterceptor, unauthorizedInterceptor]),
    ),
    provideRouter(routes),
    {
      provide: ApplicationInsights,
      useFactory: appInsightsFactory,
    },
    NG_EVENT_PLUGINS,
  ],
};
