import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LoginResponse } from '../models/responses/login.response';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  
  private readonly httpClient = inject(HttpClient);

  public authenticate(token?: string) {
    return this.httpClient.post<LoginResponse>(
      `${environment.apiBaseUrl}/deposit/authenticate`,
      {
        token,
      },
    );
  }
}
