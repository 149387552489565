import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Status } from '@shared/models/enums/status.enum';
import { MenuAction } from '@shared/models/menu-action';
import { TuiIcon } from '@taiga-ui/core';

@Component({
  selector: 'pxc-menu-actions',
  standalone: true,
  imports: [CommonModule, TuiIcon],
  templateUrl: './menu-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuActionsComponent<T> {
  @Input() entity?: T;
  @Input() actions: MenuAction<T>[] = [];

  Status = Status;
}
