import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';

const BEARER_TOKEN = 'bearer-token';
const DEPOSIT_TOKEN = 'deposit-token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  
  private bearerToken?: string;
  private teledepositToken?: string;
  private localStorageService = inject(LocalStorageService);

  public setBearerToken(bearerToken: string): void {
    this.bearerToken = bearerToken;
    this.localStorageService.set(BEARER_TOKEN, bearerToken);
  }

  public getBearerToken(): string | undefined {
    return this.bearerToken ?? this.localStorageService.get(BEARER_TOKEN);
  }

  public clearBearerToken() {
    this.bearerToken = undefined;
    this.localStorageService.remove(BEARER_TOKEN);
  }

  public setTeledepositToken(teledepositToken: string): void {
    this.teledepositToken = teledepositToken;
    this.localStorageService.set(DEPOSIT_TOKEN, teledepositToken);
  }

  public getTeledepositToken(): string | undefined {
    return this.teledepositToken ?? this.localStorageService.get(DEPOSIT_TOKEN);
  }

  public clearTeledepositToken() {
    this.teledepositToken = undefined;
    this.localStorageService.remove(DEPOSIT_TOKEN);
  }
}
