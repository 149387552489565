import { Routes } from '@angular/router';
import { LoginWithoutPasswordComponent } from '@core/authentication/login-without-password/login-without-password.component';
import { MainLayoutComponent } from '@core/components/main-layout/main-layout.component';
import { ServerUnavailableComponent } from '@core/components/server-unavailable/server-unavailable.component';
import { DepositTokenErrorComponent } from '@core/errors/deposit-token-error/deposit-token-error.component';
import { depositKeyGuard } from '@core/guards/deposit-key.guard';
import { isAuthenticatedUserGuard } from '@core/guards/is-authenticated-user.guard';
import { TeledepositService } from '@features/teledeposit/services/teledeposit.service';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        canActivate: [depositKeyGuard],
        component: LoginWithoutPasswordComponent,
      },
      {
        path: 'deposit-token-error',
        component: DepositTokenErrorComponent,
      },
      {
        path: 'server-unavailable',
        component: ServerUnavailableComponent,
      },
      {
        path: 'teledeposit',
        canActivate: [depositKeyGuard, isAuthenticatedUserGuard],
        providers: [
          TeledepositService,
        ],
        loadChildren: () =>
          import('./features/teledeposit/teledeposit.routes').then((m) => m.routes),
      },
    ]
  },
];
