<div
  class="max-md:bg-white/90 max-md:backdrop-blur max-md:border-b max-md:border-b-slate-200 flex gap-1 h-14 lg:px-2 items-center z-20 md:pointer-events-none"
>
  <a
    tuiButton
    appearance="flat"
    size="s"
    queryParamsHandling="merge"
    class="pointer-events-auto !w-fit"
  >
    <img
      src="https://cdn.pixacare.com/logo/left-transparent.svg"
      class="h-full"
      alt="logo"
    />
  </a>

  <div
    tuiDropdownOpen
    [tuiDropdown]="helpMenuActions"
    class="ml-auto pointer-events-auto"
  >
    <button
      tuiIconButton
      iconStart="@tui.circle-help"
      appearance="flat"
    ></button>
  </div>
</div>

<ng-template #helpMenuActions>
  <pxc-menu-actions [actions]="helpActions"></pxc-menu-actions>
</ng-template>
