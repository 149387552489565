/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { TuiAlertService } from '@taiga-ui/core';
import { AuthenticationApiService } from '../services/authentication.api.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'pxc-login-without-password',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    LoaderComponent
  ],
  templateUrl: './login-without-password.component.html',
})
export class LoginWithoutPasswordComponent implements OnInit {
  
  @Inject(TuiAlertService)
  private readonly alertService = inject(TuiAlertService);
  private readonly router = inject(Router);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly authenticationApiService = inject(AuthenticationApiService);
  
  ngOnInit(): void {
    this.login();
  }

  private login(): void {
    const depositToken = this.authenticationService.getTeledepositToken();
    this.authenticationApiService.authenticate(depositToken).subscribe({
      next: ({ token }) => {
        this.onLoginSuccess({ bearerToken: token });
      },
      error: (err) => {
        this.onLoginError(err);
      },
    });
  }

  private onLoginSuccess({ bearerToken }: { bearerToken: string }) {
    this.authenticationService.setBearerToken(bearerToken);
    this.router.navigate(['teledeposit']);
  }

  private onLoginError(err: any) {
    if (err.status === 400 || err.status === 401 || err.status === 404) {
      this.router.navigate(['deposit-token-error']);
    } else {
      console.error(err);
      const message = `Une erreur s'est produite, merci de réessayer plus tard.`;
      const options = { label: 'Erreur système' };
      this.alertService.open(message, options).subscribe();
      this.router.navigate(['server-unavailable']);
    }
  }
}
