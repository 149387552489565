import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/services/authentication.service';

export const isAuthenticatedUserGuard: CanActivateFn = () => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  const bearerToken = authenticationService.getBearerToken();
  const isAuthenticated = !!bearerToken;

  if (!isAuthenticated) {
    router.navigate(['/']);
    return false;
  }

  return true;
};
